import axios from 'axios';

import { config } from "../config/config"

export class dataService {
  static url = `${config.apiUrl}/data`;

  static async getData(username){
    try {
        let requestData = {
            username: username
        }
        const response = await axios.post(this.url, requestData);
        //converting to usable object

        let startObject = response.data.data;
        let destinationArray = new Array();
        let destinationObject = {
            'datauseredgefollowedbycount': startObject.followers,
            'datauseredgeownertotimelinemediaedges0nodeshortcode': startObject.allLinks[0],
            'datauseredgeownertotimelinemediaedges1nodeshortcode': startObject.allLinks[1],
            'datauseredgeownertotimelinemediaedges2nodeshortcode': startObject.allLinks[2],
            'datauseredgeownertotimelinemediaedges3nodeshortcode': startObject.allLinks[3],
            'datauseredgeownertotimelinemediaedges4nodeshortcode': startObject.allLinks[4],
            'datauseredgeownertotimelinemediaedges5nodeshortcode': startObject.allLinks[5],
            'datauseredgeownertotimelinemediaedges0nodeedgelikedbycount': startObject.allLikes[0],
            'datauseredgeownertotimelinemediaedges1nodeedgelikedbycount': startObject.allLikes[1],
            'datauseredgeownertotimelinemediaedges2nodeedgelikedbycount': startObject.allLikes[2],
        }
        destinationArray.push(destinationObject);
        console.log(destinationArray);
        return response.data.data;
      } catch (error) {
        return error;
      }
  }
}