import { config } from "../config/config"

import { jobcallerService } from './jobcallerService';


export class jobDispatcherService {
  static url = `${config.apiUrl}/list`;

  static async dispatchJobs(data) {
    try {
        let job_type = null;
        let job_jobStatus = null;
        let job_wjsApiKey = null;
        let job_link = null;
        let job_quantity = null;

        let jobData = null;
        let last6picsArray = null;

        let job_username = null;
        let job_min = null;
        let job_max = null;
        let job_post_number = null;

        //Processing a trial or a kickoff
        console.log("Processing follow job");
        job_type = 'follow';
        job_jobStatus = 'pending';
        job_wjsApiKey = data.wjsApiKey;
        job_link = 'https://www.instagram.com/' + data.instagram + '/';
        job_quantity = data.follower_uplift;

        jobData = {
            type: job_type,
            jobStatus: job_jobStatus,
            wjsApiKey: job_wjsApiKey,
            link: job_link,
            quantity: job_quantity
        };

        await jobcallerService.addToJob(jobData)
        console.log("Done processing follow job");

        console.log("Processing 6x like job");
        last6picsArray = data.last6pics_links;
        for (let pic in last6picsArray){
            job_type ="like";
            job_jobStatus = "pending";
            job_wjsApiKey = data.wjsApiKey;
            job_link = last6picsArray[pic];
            job_quantity = Math.floor(Math.random() * (120 - 100 + 1) + 100);

            jobData = {
                type: job_type,
                jobStatus: job_jobStatus,
                wjsApiKey: job_wjsApiKey,
                link: job_link,
                quantity: job_quantity
            };
            await jobcallerService.addToJob(jobData);
            console.log("Done processing pic #" + pic + " as " + job_link);
        }
        console.log("Done processing 6x like job");

        let isAKickOff = true;
        switch (data.type) {
          case 'kickoff49':
              job_min = 100;
              job_max = 125;
            break;
          case 'kickoff99':
            job_min = 130;
            job_max = 157;
            break;
          case 'kickoff199':
            job_min = 149;
            job_max = 181;
            break;
          case 'kickoff299':
            job_min = 181;
            job_max = 208;
            break;
          case 'trial':
            isAKickOff = false;
            break;
          default:
            isAKickOff = false;
            console.log("Couldn't find the type of plan");
        }

        if (isAKickOff){
            console.log("Processing autoliker");
            job_type = 'autolike';
            job_jobStatus = 'pending';
            job_wjsApiKey = data.wjsApiKey;
            job_link = null;
            job_quantity = null;
            job_username = data.instagram;
            job_post_number = 999;

            jobData = {
                type: job_type,
                jobStatus: job_jobStatus,
                wjsApiKey: job_wjsApiKey,
                username: job_username,
                min: job_min,
                max: job_max,
                post_number: job_post_number
            };
            await jobcallerService.addToJob(jobData);
            console.log("Done processing autoliker for " + job_username);
        }
        return true; //fully done dispatching

    } catch (error) {
      return error;
    }
  }

}