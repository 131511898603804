export const GET_LIST = "GET_LIST";
export const ADD_TO_LIST = "ADD_TO_LIST";
export const REMOVE_FROM_LIST = "REMOVE_FROM_LIST";
export const UPDATE_LIST = "UPDATE_LIST";

export const GET_JOB = "GET_JOB";
export const ADD_TO_JOB = "ADD_TO_JOB";
export const REMOVE_FROM_JOB = "REMOVE_FROM_JOB";
export const UPDATE_JOB = "UPDATE_JOB";

export const SET_ERROR = "SET_ERROR";

export const START_LOADING = "START_LOADING";
export const END_LOADING = "END_LOADING";