import axios from 'axios';

import { config } from "../config/config"

export class jobcallerService {
  static url = `${config.apiUrl}/job`;

  
  static async fetchJob() {
    try {
      const response = await axios.get(this.url);
      return response.data.data;
    } catch (error) {
      return error;
    }
  }

  static async addToJob(data) {
    try {
      const response = await axios.post(this.url, data);
      return response.data.data;
    } catch (error) {
      return error;
    }
  }

  static async updateJob(data) {
    try {
      await axios.put(this.url, data);
      return data;
    } catch (error) {
      return error;
    }
  }

  static async deleteFromJob(id) {
    try {
      await axios.delete(this.url, { data: { id } });
      return id;
    } catch (error) {
      return error;
    }
  }
  
}