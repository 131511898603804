import {
  GET_LIST,
  ADD_TO_LIST,
  REMOVE_FROM_LIST,
  UPDATE_LIST,
  GET_JOB,
  ADD_TO_JOB,
  REMOVE_FROM_JOB,
  UPDATE_JOB
} from './actionTypes';

export default function reducer(state, action) {
  switch (action.type) {
    case GET_LIST:
      return {
        ...state,
        list: action.payload
      }
    case ADD_TO_LIST:
      const newItem = action.payload;
      return {
        ...state,
        list: [...state.list, newItem]
      }
    case UPDATE_LIST:
      const editedList = action.payload;
      const updatedList = state.list.map(item => item._id === editedList._id ? editedList : item)
      return {
        ...state,
        list: updatedList
      }
    case REMOVE_FROM_LIST:
      const filteredList = state.list.filter(item => item._id !== action.payload)
      return {
        ...state,
        list: filteredList
      }
      case GET_JOB:
        return {
          ...state,
          job: action.payload
        }
      case ADD_TO_JOB:
        const newJob = action.payload;
        return {
          ...state,
          job: [...state.job, newJob]
        }
      case UPDATE_JOB:
        const editedJob = action.payload;
        const updatedJob = state.job.map(item => item._id === editedJob._id ? editedJob : item)
        return {
          ...state,
          job: updatedJob
        }
      case REMOVE_FROM_JOB:
        const filteredJob = state.job.filter(item => item._id !== action.payload)
        return {
          ...state,
          job: filteredJob
        }
    default:
      {
        return state;
      }
  }
}