import React, { useContext, useReducer, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPlus } from '@fortawesome/free-solid-svg-icons'
import { faFingerprint } from '@fortawesome/free-solid-svg-icons'

import Store from './store/store';
import reducer from './store/reducer';

import List from './components/List/List';
import Form from './components/Form/Form';
import Image from './components/Image/Image';

import style from './App.module.scss';

const INITIAL_VISIBILITY = {
  showWjsApiInput: false,
  showWjsMailInput: false
}
const INITIAL_WJSKEY = {
  wjsApiKey: ''
}

const App = () => {
  const initialState = useContext(Store);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [formView, setFormView] = useState(false);

  const [visibilityState, setVisibilityState] = useState(INITIAL_VISIBILITY);
  const [wjsKeyState, setwjsKeyState] = useState(INITIAL_WJSKEY);


  const [edit, setEdit] = useState(false);

  const setView = () => {
    setEdit(null);
    setFormView(!formView)
  };

  const closeForm = () => setFormView(false);

  const onWjsKeyChange = e => {
    const { name, value } = e.target;
    setwjsKeyState((prevState) => ({ ...prevState, [name]: value }));
  };

  const toggleWjsApiInput = () => {
    if (visibilityState.showWjsApiInput === false){
      setVisibilityState({
        showWjsApiInput: true
      });
    }else{
      setVisibilityState({
        showWjsApiInput: false
      });
    }
  };

  const toggleWjsMailInput = () => {
    if (visibilityState.showWjsMailInput === false){
      setVisibilityState({
        showWjsMailInput: true
      });
    }else{
      setVisibilityState({
        showWjsMailInput: false
      });
    }
  };

  const editItem = item => {
    setEdit(item);
    setFormView(true);
  };

  const clearEdit = () => {
    setEdit(false);
  }

  return (
    <Store.Provider value={{ state, dispatch }}>
      <div className={style.app}>
        <div className={style.todo}>
          <Image />
          <span className={style.button} onClick={setView}>
            <FontAwesomeIcon icon={faPlus} className={formView ? style.rotate : ''} />
          </span>
          <span className={style.buttonApi} onClick={toggleWjsApiInput}>
            <FontAwesomeIcon icon={faFingerprint} className={formView ? style.rotate : ''} />
          </span>
          <span className={style.buttonMail} onClick={toggleWjsMailInput}>
            <FontAwesomeIcon icon={faEnvelope} className={formView ? style.rotate : ''} />
          </span>
          <div className={style.body}>
          {visibilityState.showWjsApiInput && (<input
            type="text"
            name="wjsApiKey"
            placeholder="Paste WJS API key"
            value={wjsKeyState.wjsApiKey}
            onChange={onWjsKeyChange}
          />)}
          {visibilityState.showWjsMailInput && (
          <button onClick={() => {
            let name = prompt('Please enter the firstname');
            let ig = prompt("Please enter the Instagram account");
            console.log("Get Offering Template"); 
            let templateText = 
`Hi ${name},

It's great to hear from you and thanks a lot for letting me know 😊.

I thought I'd give you more details on how this works. We have partner sites and platforms on which we create custom ads/promotions so that your content is viewed. We found that if your content is good and it's viewed enough, engagement and followers will rise too.

I should clarify that since we drive (quite a bit) of visibility to your profile that is public, we can't "prevent" bad followers from also following your profile. That means that by raising the overall visibility of your profile, it is possible that some lower quality users end up following you. Great news on this, we automatically report any low quality accounts (bots or similar) to Instagram for removal. That's a fully automated process on our end!

Based on those extra details, should I go ahead and kick off a 24 hours trial on ${ig}?

Have a wonderful day!

Best,
Jessica,`;
            navigator.clipboard.writeText(templateText);
            }}>
              Get Offering Template
          </button>
          )}
          {
              formView
                ? <Form closeForm={closeForm} edit={edit} clearEdit={clearEdit} wjsApiKey={wjsKeyState.wjsApiKey} />
                : <List editItem={editItem} />
          }
          </div>
        </div>
        <a href="https://wejustsocial.com">Built with ❤️ from around the 🌎</a>
      </div>
    </Store.Provider>
  );
}

export default App;
