export const config = {
  apiUrl: 'https://wjs-crm-v01a.herokuapp.com/api/v1'
  //apiUrl: 'http://localhost:4000/api/v1'
}

//dev-use   
//apiUrl: 'http://localhost:4000/api/v1'

//prod-use
//apiUrl: 'https://wjs-crm-v01a.herokuapp.com/api/v1'
