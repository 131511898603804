import React, { useContext, useEffect, useState } from 'react';

import ReactTooltip from "react-tooltip";

import Store from '../../store/store';
import { ADD_TO_LIST, UPDATE_LIST } from '../../store/actionTypes';

import { listService } from '../../services/listService';
import { dataService } from '../../services/dataService';

import style from './Form.module.scss';
import { faRandom } from '@fortawesome/free-solid-svg-icons';

const INITIAL_LIST = {
  firstname: '',
  instagram: '',
  type: 'trial',
  follower_uplift: '',
  followers: '',
  last3pics_likes: [],
  last6pics_links: [],
  wjsApiKey: ''
}

const INITIAL_INNERVARIABLE = {
  editMode: false,
  last6pic_console_dump: '',
  user_data_followers: '',
  user_data_pic1_likes: '',
  user_data_pic2_likes: '',
  user_data_pic3_likes: '',
  result_console_dump: '',
  user_data_followers_after: '',
  user_data_pic1_likes_after: '',
  user_data_pic2_likes_after: '',
  user_data_pic3_likes_after: '',
}

const Form = ({ closeForm, edit, clearEdit, wjsApiKey }) => {
  const { dispatch } = useContext(Store);
  const [form, setForm] = useState(INITIAL_LIST);

  const [innerVariableState, setInnerVariableState] = useState(INITIAL_INNERVARIABLE);
  if (form.wjsApiKey === ''){
    form.wjsApiKey = wjsApiKey;
  }else{
    //console.log("WARNING Debug mode active");
  }




  useEffect(() => {
    if (edit) {
      setForm(edit);
      console.log("We're in edit mode");
      setInnerVariableState((prevState) => ({ ...prevState, editMode: true})); //update the value of the field.
    }else{
      console.log("We're NOT in edit mode");
      setInnerVariableState((prevState) => ({ ...prevState, editMode: false})); //update the value of the field.
    }
  }, [edit])


  const onFormChange = e => {
    const { name, value } = e.target;

    if (name === "instagram"){
      console.log("Changing IG field");
    }

    if (name === "last6pic_console_dump"){
      setInnerVariableState((prevState) => ({ ...prevState, [name]: value })); //update the value of the field.
      console.log("Changing dump value"); 
      let userdata = JSON.parse(value);

      let user_data_pic1_link = null;
      let user_data_pic1_likes = null;
      let user_data_pic2_link = null;
      let user_data_pic2_likes = null;
      let user_data_pic3_link = null;
      let user_data_pic3_likes = null;
      let user_data_pic4_link = null;
      let user_data_pic5_link = null;
      let user_data_pic6_link = null;
      let user_data_followers = null;

      const getType = obj => Object.prototype.toString.call(obj).slice(8, -1);
      const isArray = obj => getType(obj) === 'Array';

      if (isArray(userdata)){ //new json processing
        console.log("New json processing during create mode v2");
        userdata = userdata[0];
        user_data_pic1_link = userdata.datauseredgeownertotimelinemediaedges0nodeshortcode;
        user_data_pic1_likes = userdata.datauseredgeownertotimelinemediaedges0nodeedgelikedbycount;
        user_data_pic2_link = userdata.datauseredgeownertotimelinemediaedges1nodeshortcode;
        user_data_pic2_likes = userdata.datauseredgeownertotimelinemediaedges1nodeedgelikedbycount;
        user_data_pic3_link = userdata.datauseredgeownertotimelinemediaedges2nodeshortcode;
        user_data_pic3_likes = userdata.datauseredgeownertotimelinemediaedges2nodeedgelikedbycount;
        user_data_pic4_link = userdata.datauseredgeownertotimelinemediaedges3nodeshortcode;
        user_data_pic5_link = userdata.datauseredgeownertotimelinemediaedges4nodeshortcode;
        user_data_pic6_link = userdata.datauseredgeownertotimelinemediaedges5nodeshortcode;
        user_data_followers = userdata.datauseredgefollowedbycount;
      }
      else  {
        console.log("Legacy json processing during create mode");
      user_data_followers = userdata.graphql.user.edge_followed_by.count;
      user_data_pic1_link = userdata.graphql.user.edge_owner_to_timeline_media.edges[0].node.shortcode;
      user_data_pic1_likes = userdata.graphql.user.edge_owner_to_timeline_media.edges[0].node.edge_liked_by.count;
      user_data_pic2_link = userdata.graphql.user.edge_owner_to_timeline_media.edges[1].node.shortcode;
      user_data_pic2_likes = userdata.graphql.user.edge_owner_to_timeline_media.edges[1].node.edge_liked_by.count;
      user_data_pic3_link = userdata.graphql.user.edge_owner_to_timeline_media.edges[2].node.shortcode;
      user_data_pic3_likes = userdata.graphql.user.edge_owner_to_timeline_media.edges[2].node.edge_liked_by.count;
      user_data_pic4_link = userdata.graphql.user.edge_owner_to_timeline_media.edges[3].node.shortcode;
      user_data_pic5_link = userdata.graphql.user.edge_owner_to_timeline_media.edges[4].node.shortcode;
      user_data_pic6_link = userdata.graphql.user.edge_owner_to_timeline_media.edges[5].node.shortcode;
      }

      user_data_pic1_link = 'https://www.instagram.com/p/' + user_data_pic1_link + '/';
      user_data_pic2_link = 'https://www.instagram.com/p/' + user_data_pic2_link + '/';
      user_data_pic3_link = 'https://www.instagram.com/p/' + user_data_pic3_link + '/';
      user_data_pic4_link = 'https://www.instagram.com/p/' + user_data_pic4_link + '/';
      user_data_pic5_link = 'https://www.instagram.com/p/' + user_data_pic5_link + '/';
      user_data_pic6_link = 'https://www.instagram.com/p/' + user_data_pic6_link + '/';

      setInnerVariableState({
        last6pic_console_dump: form.last6pic_console_dump,
        user_data_followers: user_data_followers,
        user_data_pic1_likes: user_data_pic1_likes,
        user_data_pic2_likes: user_data_pic2_likes,
        user_data_pic3_likes: user_data_pic3_likes
      });

      console.log("Has " + user_data_followers + " followers.");

      console.log("Last 3 pics have this many likes:");
      console.log(user_data_pic1_likes + ', ' + user_data_pic2_likes + ', ' + user_data_pic3_likes);

      console.log("Changed start followers"); //Adjust follower Uplift on the fly.
      let allocation = null;
      let pricePer1000 = 240;
      let pricePerFollowers = pricePer1000/1000;
      let goal = user_data_followers*0.2;
      goal = Math.floor(goal);
      console.log("Found Goal as " + goal);
      let goalCost = goal*pricePerFollowers;
      console.log("Found Goalcost as " + goalCost);
      if (goalCost < 60){ //Cost is lower than 60 cents
        allocation = 60/pricePerFollowers;
        console.log("20% would be less than 60 cent, allocating 60 cent at " + allocation + " followers.");
      }
      else if (goalCost > 90){ //Cost higher than 90 cents
        allocation = 90/pricePerFollowers;
        console.log("20% would be more than 90 cent, allocating 90 cent at " + allocation + " followers.");
      }
      else{ //In range
        allocation = goalCost/pricePerFollowers;
        console.log("Allocating 20% uplift at " + allocation + " followers.");
      }
      form.follower_uplift = Math.floor(allocation);

      //console.log("Filling Last 6 pics array"); //Filling Last 6 pics array
      let last6PicsArray = [];
      last6PicsArray.push(user_data_pic1_link);
      last6PicsArray.push(user_data_pic2_link);
      last6PicsArray.push(user_data_pic3_link);
      last6PicsArray.push(user_data_pic4_link);
      last6PicsArray.push(user_data_pic5_link);
      last6PicsArray.push(user_data_pic6_link);
      form.last6pics_links = last6PicsArray;

      //console.log("Filling Last 3 pics likes"); //Filling Last 3 pics likes
      let last3PicsLikes = [];
      last3PicsLikes.push(user_data_pic1_likes);
      last3PicsLikes.push(user_data_pic2_likes);
      last3PicsLikes.push(user_data_pic3_likes);
      form.last3pics_likes = last3PicsLikes;

      //setting the number of followers
      form.followers = user_data_followers;

    }
    setForm((prevState) => ({ ...prevState, [name]: value }));
  }

  const onInnerVarChange = e => {
    const { name, value } = e.target;

    if (name === "result_console_dump"){
      setInnerVariableState((prevState) => ({ ...prevState, [name]: value })); //update the value of the field.
      console.log("Changing result dump value during edit mode"); 
      let userdata = JSON.parse(value);

      let user_data_pic1_link = null;
      let user_data_pic1_likes = null;
      let user_data_pic2_link = null;
      let user_data_pic2_likes = null;
      let user_data_pic3_link = null;
      let user_data_pic3_likes = null;
      let user_data_followers = null;


      const getType = obj => Object.prototype.toString.call(obj).slice(8, -1);
      const isArray = obj => getType(obj) === 'Array';


      if (isArray(userdata)){ //new json processing
        console.log("New json processing during edit mode v2");
        userdata = userdata[0];
        user_data_pic1_link = userdata.datauseredgeownertotimelinemediaedges0nodeshortcode;
        user_data_pic1_likes = userdata.datauseredgeownertotimelinemediaedges0nodeedgelikedbycount;
        user_data_pic2_link = userdata.datauseredgeownertotimelinemediaedges1nodeshortcode;
        user_data_pic2_likes = userdata.datauseredgeownertotimelinemediaedges1nodeedgelikedbycount;
        user_data_pic3_link = userdata.datauseredgeownertotimelinemediaedges2nodeshortcode;
        user_data_pic3_likes = userdata.datauseredgeownertotimelinemediaedges2nodeedgelikedbycount;
        user_data_followers = userdata.datauseredgefollowedbycount;
      }
      else{
        console.log("Legacy json processing during edit mode");
      user_data_followers = userdata.graphql.user.edge_followed_by.count;
      user_data_pic1_link = userdata.graphql.user.edge_owner_to_timeline_media.edges[0].node.shortcode;
      user_data_pic1_likes = userdata.graphql.user.edge_owner_to_timeline_media.edges[0].node.edge_liked_by.count;
      user_data_pic2_link = userdata.graphql.user.edge_owner_to_timeline_media.edges[1].node.shortcode;
      user_data_pic2_likes = userdata.graphql.user.edge_owner_to_timeline_media.edges[1].node.edge_liked_by.count;
      user_data_pic3_link = userdata.graphql.user.edge_owner_to_timeline_media.edges[2].node.shortcode;
      user_data_pic3_likes = userdata.graphql.user.edge_owner_to_timeline_media.edges[2].node.edge_liked_by.count;

    }

    setInnerVariableState((prevState) => ({ ...prevState,
      user_data_followers_after: user_data_followers,
      user_data_pic1_likes_after: user_data_pic1_likes,
      user_data_pic2_likes_after: user_data_pic2_likes,
      user_data_pic3_likes_after: user_data_pic3_likes
    })); //update the value of the after data.

    user_data_pic1_link = 'https://www.instagram.com/p/' + user_data_pic1_link + '/';
    user_data_pic2_link = 'https://www.instagram.com/p/' + user_data_pic2_link + '/';
    user_data_pic3_link = 'https://www.instagram.com/p/' + user_data_pic3_link + '/';

    console.log("Result, now has " + user_data_followers + " followers.");
    console.log("Result, Last 3 pics have this many likes:");
    console.log(user_data_pic1_likes + ', ' + user_data_pic2_likes + ', ' + user_data_pic3_likes);
    }

    setForm((prevState) => ({ ...prevState, [name]: value }));
  }

  const clearForm = () => {
    setForm(INITIAL_LIST);
    closeForm();
  }

  const formSubmit = async () => {
    if (form.wjsApiKey == ''){
      console.log("Invalid API key");
      return;
    }
    if (form.last6pic_console_dump){
      form.last6pic_console_dump = null;
    }
    if (edit) {
      const newItem = await listService.updateList(form);
      dispatch({
        type: UPDATE_LIST,
        payload: newItem
      })
      clearForm();
      clearEdit();
      return;
    }
    const newItem = await listService.addToList(form);
    dispatch({
      type: ADD_TO_LIST,
      payload: newItem
    })
    clearForm();
  }

  function resultConsoleDumpField(){
    if (innerVariableState.editMode){
      return <textarea
      type="text"
      name="result_console_dump"
      rows="4"
      placeholder="Paste RESULTS console dump here from previous command"
      value={innerVariableState.result_console_dump}
      onChange={onInnerVarChange}
    />
    }else{
      return <textarea
        type="text"
        name="last6pic_console_dump"
        rows="4"
        placeholder="Paste console dump here from previous command"
        value={innerVariableState.last6pic_console_dump}
        onChange={onFormChange}
      />
    }
  }

  function pullDataButton(){
    return <button className={style.buttonSmall} onClick={() => {
      console.log("Getting account Data"); 
      dataService.getData(form.instagram);
    }}>Pull Data
  </button>
  }

  function startEmailButton(){
    return <button className={style.buttonSmall} onClick={() => {
      console.log("Get Launching Email"); 
      let launchingEmailTemplate = 
`Hi ${form.firstname},

Thanks for mailing back!

I've kicked off a 24-hour trial on your account ${form.instagram} that's at ${innerVariableState.user_data_followers} followers. Just so that it's easier to find this information later, on the engagement front, your 3 most recent pictures have ${innerVariableState.user_data_pic1_likes}, ${innerVariableState.user_data_pic2_likes} and ${innerVariableState.user_data_pic3_likes} likes respectively 😊.

I'll touch base again soon, ${form.firstname}, so that we can discuss the results together 🙌​.

Best,
Chris,`;
    navigator.clipboard.writeText(launchingEmailTemplate);
    }}>Get Launching Email EN
  </button>
  }

  function startEmailButtonSP(){
    return <button className={style.buttonSmall} onClick={() => {
      console.log("Get Launching Email SP"); 
      let launchingEmailTemplate = 
`Hola ${form.firstname},

¡Gracias por comunicarte nuevamente!

Hemos empezado la prueba gratis de 24 horas en su cuenta ${form.instagram} la cual tiene ${innerVariableState.user_data_followers} seguidores. Para que se facilite encontrar la siguiente información más adelante, con respecto a la interacción de su cuenta, sus 3 publicaciones más recientes tienen ${innerVariableState.user_data_pic1_likes}, ${innerVariableState.user_data_pic2_likes} y ${innerVariableState.user_data_pic3_likes} likes respectivamente.

Me pondré en contacto próximamente con usted para discutir más a fondo los resultados.

Saludos,
Sebastian.`;
    navigator.clipboard.writeText(launchingEmailTemplate);
    }}>Get Launching Email SP
  </button>
  }

  function resultEmailButton(){
    if (innerVariableState.editMode){
      let customLink = 'https://wejustsocial.com/pricing/?vipIg=' + form.instagram;

      return <button data-tip data-for="getResultEmail" className={style.buttonSmall} onClick={() => {
        console.log("Get Result Email");

        let ResultEmailTemplate = 
`Hi ${form.firstname},

I hope you're doing well!

On your account ${form.instagram}, I can see that you now have ${innerVariableState.user_data_followers_after} followers (you had ${form.followers}) and that your 3 pictures that we've promoted now have ${innerVariableState.user_data_pic1_likes_after}, ${innerVariableState.user_data_pic2_likes_after}, ${innerVariableState.user_data_pic3_likes_after} likes respectively (from ${form.last3pics_likes[0]}, ${form.last3pics_likes[1]}, ${form.last3pics_likes[2]} before 🎉).

I think your account is a prime example of a good looking IG that responds really well to our promotion. I should mention that we didn't run promotions on your new posts after the kickoff of the 24 hour trial. If you were on one of our plans, you can expect that all your new posts would have gotten about as much engagement if they were good quality 🙌.

Do you see the value in social proofing and reaching a wider audience on Instagram? 

You can check out the packages we offer here: https://wejustsocial.com/pricing/. We are currently offering 5% off the $99 premium package for 3 months or 10% off the $199 business package for 6 months (month-to-month, no contract).

Let me know what you think! I'd love to discuss more about this with you!

Cheers, 
Chris,`;
      navigator.clipboard.writeText(ResultEmailTemplate);
      }}>Get Result Email EN
    </button>
    }
  }

  function resultEmailButtonSP(){
    if (innerVariableState.editMode){
      let customLink = 'https://wejustsocial.com/pricing/?vipIg=' + form.instagram;

      return <button data-tip data-for="getResultEmail" className={style.buttonSmall} onClick={() => {
        console.log("Get Result Email SP");

        let ResultEmailTemplate = 
`Hola ${form.firstname},

Espero que se encuentre bien!!

En su cuenta ${form.instagram}, Podemos ver que usted ahora tiene ${innerVariableState.user_data_followers_after} seguidores (usted tenia ${form.followers}) y que las 3 imágenes que promovimos ahora tienen ${innerVariableState.user_data_pic1_likes_after}, ${innerVariableState.user_data_pic2_likes_after}, ${innerVariableState.user_data_pic3_likes_after} likes respectivamente  (usted antes tenía ${form.last3pics_likes[0]},${form.last3pics_likes[1]} y ${form.last3pics_likes[2]}).

Su cuenta de Instagram es un excelente ejemplo de una cuenta atractiva de IG que respondió bien a nuestra promoción. Cabe mencionar que no corrimos la promoción en su más reciente publicación después de la prueba gratis de 24 hrs. Si usted estuviera en nuestro plan, puede esperar la misma respuesta obtenida con los seguidores y likes si el contenido es de buena calidad.

¿Puede observar el valor de social proofing (demostración / seguridad social) y llegar a una audiencia más amplia?  

Puede ver los paquetes que ofrecemos en el siguiente link: https://wejustsocial.com/pricing/  (mes con mes, sin necesidad de un contrato). Estamos ofreciendo el 5% descuento en nuestro paquete Premium por 3 meses o 10% descuento en nuestro paquete de negocios por 6 meses en cada una de las cuentas que suscriba. 

Hazme saber lo que piensas! ¡Me encantaría discutirlo con usted!

Saludos, 
Seb,`;
      navigator.clipboard.writeText(ResultEmailTemplate);
      }}>Get Result Email SP
    </button>
    }
  }

  return (
    <div className={style.form}>
      <input
        type="text"
        name="firstname"
        placeholder="first name"
        value={form.firstname}
        onChange={onFormChange}
      />
      <input
        type="text"
        name="instagram"
        placeholder="instagram"
        value={form.instagram}
        onChange={onFormChange}
      />
      {pullDataButton()}
      <select name="type" value={form.type} onChange={onFormChange}>
        <option value="trial">Trial</option>
        <option value="kickoff49">Kickoff 49</option>
        <option value="kickoff99">Kickoff 99</option>
        <option value="kickoff199">Kickoff 199</option>
        <option value="kickoff299">Kickoff 299</option>
      </select>
      <button data-tip data-for="getJsonTip" className={style.buttonSmall} onClick={() => {
          console.log("onClick"); 
          let openUrl = "https://www.instagram.com/" + form.instagram + "/?__a=1";
          window.open(openUrl, "_blank")
        }}>Open JSON to copy
      </button>
      <ReactTooltip id="getJsonTip" place="top" effect="solid">
        Click to open JSON to copy...
      </ReactTooltip>
      {resultConsoleDumpField()}
      {startEmailButton()}
      {startEmailButtonSP()}
      {resultEmailButton()}
      {resultEmailButtonSP()}
      <button className={style.buttonSmall}  onClick={formSubmit}>Submit</button>
    </div>
  )
}

export default Form;